<template>
  <v-container class="my-10 mb-8 pb-8 dcl-form">
    <v-dialog v-model="submitDialog" max-width="500px">
      <v-card>
        <v-card-title>{{ resMessage.title || 'Your form has been submitted' }}</v-card-title>
        <v-card-text>
          <p>{{ resMessage.message || 'You will get a copy of your form in the email you provided' }}</p>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="submitLoader" max-width="500px">
      <v-card>
        <v-card-title>Your form is being submitted</v-card-title>
        <v-card-text>
          <p>Your form is being processed. This might take some time...</p>
        </v-card-text>
      </v-card>
    </v-dialog>


    <v-snackbar v-model="snackBar.show" :timeout="3000" :color="snackBar.color" elevation="24" location="bottom right">
      <span class="snackbar__message">{{ snackBar.text }}</span>
    </v-snackbar>

    <div class="dcl__header">
      <h2><v-btn class="me-2" icon href="javascript:history.back()"><v-icon>mdi-chevron-left</v-icon></v-btn> Minor to
        Individual Account Transfer Form</h2>

      <v-divider class="my-8"> </v-divider>
    </div>

    <div class="form-container">
      <v-form v-model="valid" ref="form">
        <v-container>
          <!-- ? BOID -->
          <v-row dense justify="center">
            <v-col cols="12" sm="10" md="10">
              <section class="pb-2">
                <div class="mb-2">
                  <span class="grey--text text--darken-3 inputLabel">
                    BOID
                  </span>
                </div>

                <v-text-field variant="outlined" placeholder="e.g. 1XXXXXXXX" density="compact" v-model="clientInfo.boid"
                  :rules="stringRules"></v-text-field>
              </section>
            </v-col>
          </v-row>
          <!-- ? Name -->
          <v-row dense justify="center">
            <v-col cols="12" sm="10" md="10">
              <section class="pb-2">
                <div class="mb-2">
                  <span class="grey--text text--darken-3 inputLabel">
                    Client's Name
                  </span>
                </div>

                <v-text-field variant="outlined" placeholder="e.g. Joseph Doe" density="compact" v-model="clientInfo.name"
                  :rules="stringRules"></v-text-field>
              </section>
            </v-col>
          </v-row>
          <!-- ? Email -->
          <v-row dense justify="center">
            <v-col cols="12" sm="10" md="10">
              <section class="pb-2">
                <div class="mb-2">
                  <span class="grey--text text--darken-3 inputLabel">
                    Client's Email (You will receive a copy of the form here)
                  </span>
                </div>

                <v-text-field variant="outlined" placeholder="e.g. Joseph Doe" density="compact"
                  v-model="clientInfo.email" :rules="stringRules"></v-text-field>
              </section>
            </v-col>
          </v-row>
          <!-- ? Father's Name -->
          <v-row dense justify="center">
            <v-col cols="12" sm="10" md="10">
              <section class="pb-2">
                <div class="mb-2">
                  <span class="grey--text text--darken-3 inputLabel">
                    Father's Name
                  </span>
                </div>

                <v-text-field variant="outlined" placeholder="e.g. Jonathan Doe" density="compact"
                  v-model="clientInfo.fatherName" :rules="stringRules"></v-text-field>
              </section>
            </v-col>
          </v-row>
          <!-- ? Mother's Name -->
          <v-row dense justify="center">
            <v-col cols="12" sm="10" md="10">
              <section class="pb-2">
                <div class="mb-2">
                  <span class="grey--text text--darken-3 inputLabel">
                    Mother's Name
                  </span>
                </div>

                <v-text-field variant="outlined" placeholder="e.g. Jane Doe" density="compact"
                  v-model="clientInfo.motherName" :rules="stringRules"></v-text-field>
              </section>
            </v-col>
          </v-row>
          <!-- ? Grandfather's Name -->
          <v-row dense justify="center">
            <v-col cols="12" sm="10" md="10">
              <section class="pb-2">
                <div class="mb-2">
                  <span class="grey--text text--darken-3 inputLabel">
                    Grand Father's Name
                  </span>
                </div>

                <v-text-field variant="outlined" placeholder="e.g. George Doe" density="compact"
                  v-model="clientInfo.grandfatherName" :rules="stringRules"></v-text-field>
              </section>
            </v-col>
          </v-row>

          <!-- ? DOB -->
          <v-row dense justify="center">
            <v-col cols="12" sm="10" md="10">
              <section class="pb-2">
                <div class="mb-2">
                  <span class="grey--text text--darken-3 inputLabel">
                    Date of Birth
                  </span>
                </div>

                <v-text-field variant="outlined" placeholder="e.g. 2000/01/01" density="compact" v-model="clientInfo.dob"
                  :rules="stringRules"></v-text-field>
              </section>
            </v-col>
          </v-row>
          <!-- ? Citizenship Number -->
          <v-row dense justify="center">
            <v-col cols="12" sm="10" md="10">
              <section class="pb-2">
                <div class="mb-2">
                  <span class="grey--text text--darken-3 inputLabel">
                    Citizenship Number
                  </span>
                </div>

                <v-text-field variant="outlined" placeholder="e.g. XX-XX-XX-XXXXX" density="compact"
                  v-model="clientInfo.citizenshipNo" :rules="stringRules"></v-text-field>
              </section>
            </v-col>
          </v-row>
          <!-- ? Client Signature -->
          <v-row dense justify="center">
            <v-col cols="12" md="10">
              <span class="text--darken-3 inputLabel">Signature</span>
            </v-col>
            <v-col cols="12" md="10">
              <v-file-input show-size accept="image/*" placeholder="Upload Signature in JPG or PNG format" flat
                density="compact" append-icon="mdi-paperclip" prepend-icon="" class="text--blue" dense
                v-model="clientInfo.signature" :rules="stringSizeRule"></v-file-input>
            </v-col>
          </v-row>
          <!-- ? Submit -->
          <v-row dense justify="center">
            <v-col cols="12" sm="10" md="10">
              <section class="pb-2">
                <v-btn elevation="0" color="success" style="text-transform: capitalize; letter-spacing: 0px;"
                  @click="submit">Submit
                  <v-icon right>mdi-content-save</v-icon></v-btn>
              </section>
            </v-col>
          </v-row>

        </v-container>

      </v-form>
    </div>
  </v-container>
</template>

<script>
import M2IService from "../services/API/M2IService"
export default {
  name: "M2I",
  data() {
    return {
      valid: false,
      submitDialog: false,
      submitLoader: false,
      snackBar: {
        show: false,
        color: 'success',
      },
      resMessage: {
        title: null,
        message: null
      },
      clientInfo: {
        boid: '',
        name: '',
        fatherName: '',
        motherName: '',
        grandfatherName: '',
        dob: '',
        citizenshipNo: '',
        signature: '',
        email: '',
      },
      stringRules: [
        v => !!v || "This field is required",
      ],
      stringSizeRule: [
        v => !!v && v.size < 1000000 || 'File is required and the file size should not exceed 1MB!'
      ],
      sizeRule: [
        v => (v ? v.size < 1000000 : true) || 'File size should not exceed 1MB!'
      ]
    }
  },
  methods: {
    async submit() {
      this.$refs['form'].validate()
      this.submitLoader = true;
      await M2IService.FillM2IForm(this.clientInfo).then((response) => {
        if (response.status != 200) {
          this.resMessage.title = "Could Not Submit Form"
          this.resMessage.message = "There was an error while submitting this form"
        }

        this.submitLoader = false;
        this.submitDialog = true;
      })
    }
  }
}
</script>

<style scoped>
.form-container {
  max-width: 800px;
  margin-inline: auto;
  border: 1px solid rgb(204, 204, 204);
  border-radius: 0.4rem;
  padding-block: 2rem;
}

.inputLabel {
  font-weight: 600 !important;
  font-size: 0.9rem;
}

.inputTitle {
  font-weight: 600 !important;
  font-size: 1.2rem;
}

.dcl__header {
  max-width: 800px;
  margin-inline: auto;
}
</style>
