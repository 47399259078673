import APIManager from './APIManager';

// ? Minor to Individual Account Transfer
const FillM2IForm = async (queryObj) => {
    const formData = new FormData();

    formData.append('name', queryObj.name);
    formData.append('email', queryObj.email);
    formData.append('fatherName', queryObj.fatherName);
    formData.append('motherName', queryObj.motherName);
    formData.append('grandfatherName', queryObj.grandfatherName);
    formData.append('dob', queryObj.dob);
    formData.append('citizenshipNo', queryObj.citizenshipNo);
    formData.append('boid', queryObj.boid);
    formData.append('signature', queryObj.signature);
    const formResponse = APIManager.axios
        .post(`/api/v1/public/minor-to-individual`, formData)
        .then((response) => {
            return response;
        });

    return formResponse;
};

export default {
    FillM2IForm,
};
